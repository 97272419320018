import React from "react"
import Button from "../../../UI/Button/button.js"
import svgLeft from "../../../../assets/images/CTA_shape_left.svg"
import svgRight from "../../../../assets/images/CTA_shape_right.svg"
import "./style.scss"
const Unsure = ({ unsureButton, unsureTitle }) => {
  return (
    <section className="section section--unsure">
      <img src={svgLeft} className="unsure__svg--left" />
      <img src={svgRight} className="unsure__svg--right" />
      <div className="unsure">
        <h2 className="unsure__title">{unsureTitle}</h2>
        <Button to="talk-to-us#free-plan" className="unsure__button">
          {unsureButton}
        </Button>
      </div>
    </section>
  )
}

export default Unsure
