import React from "react"
import Img from "gatsby-image"
import "./styles.scss"
const Founders = ({ foundersLeft, foundersRight, foundersTitle }) => {
  return (
    <section className="section section--founders">
      <div className="wrapper">
        <h2 className="founders__title">{foundersTitle}</h2>

        <div className="founders">
          <div className="founders__left">
            {foundersLeft.image && (
            <Img
              className="left__image"
              fluid={foundersLeft.image.localFile.childImageSharp.fluid}
            />
            )}
            <div className="left-content__wrapper">
              <h4 className="left__subtitle">{foundersLeft.subtitle}</h4>
              <p className="left__description">{foundersLeft.description}</p>
            </div>
          </div>
          <div className="founders__right">
            <div className="right__content-wrapper">
              <h3 className="right__title">{foundersRight.title}</h3>
              <p className="right__subtitle">{foundersRight.subtitle}</p>
            </div>
            {foundersRight.image && (
            <Img
              className="right__image"
              fluid={foundersRight.image.localFile.childImageSharp.fluid}
            />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Founders
