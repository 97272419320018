import React, { useState, useEffect } from "react"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image"
import "./style.scss"
import { Swiper, SwiperSlide } from "swiper/react"

const Slider = ({ slides, slidesTitle }) => {
  Carousel.defaultProps.indicators = false
  Carousel.defaultProps.controls = false
  Carousel.defaultProps.interval = 3000

  const [activeIndex, setIndex] = useState([0])
  const [mobile, setMobile] = useState(false)

  const updateSlider = () => {
    if (window.innerWidth < 767) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }
  useEffect(() => {
    updateSlider()
    window.addEventListener("resize", updateSlider)
  })

  return (
    <section className="section section--slider">
      <div className="wrapper">
        <h2 className="slider__title">{slidesTitle}</h2>

        {mobile ? (
          <div className="slider--mobile">
            <Swiper spaceBetween={50} slidesPerView={1.5}>
              {slides.map((slide, i) => (
                <SwiperSlide key={i}>
                  {slide.image && (
                  <Img
                    className="slide--mobile__image"
                    fluid={slide.image.localFile.childImageSharp.fluid}
                  />
                  )}
                  <span className="slide--mobile__counter">0{i + 1}</span>
                  <span className="slide--mobile__text">{slide.text}</span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          <div className="slider">
            <div className="slider__counter">
              {slides.map((slide, i) => (
                <span
                  className={` ${activeIndex.includes(i) ? "active" : ""}`}
                  key={i}
                >
                  0{i + 1}
                </span>
              ))}
            </div>
            <Carousel
              onSlide={i => {
                if (i === 0) {
                  setIndex([0])
                } else {
                  setIndex([...activeIndex, i])
                }
              }}
              className="slider__wrapper"
            >
              {slides.map((slide, i) => (
                <Carousel.Item className="slide" key={i}>
                  {slide.image && (
                  <Img
                    className="slide__image"
                    fluid={slide.image.localFile.childImageSharp.fluid}
                  />
                  )}
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="slider__text">
              {slides.map((slide, i) => (
                <p
                  className={` ${activeIndex.includes(i) ? "active" : ""}`}
                  key={i}
                >
                  {slide.text}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default Slider
