import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Sections/Home/Hero/hero.js"
import Underhero from "../components/Sections/Home/Underhero/underhero.js"
import CTA from "../components/Sections/CTA"
import WhereCanWeHelpYou from "../components/Sections/Home/WhereCanWeHelpYou"
import Slider from "../components/Sections/Home/Slider"
import Founders from "../components/Sections/Home/Founders"
import Unsure from "../components/Sections/Home/Unsure"
import ogImage from "../assets/images/og_image.png"

const query = graphql`
  {
    wordpressPage(path: { eq: "/~sistable/" }) {
      acf {
        seo_title
        seo_description
        cta_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        cta_text
        cta_button {
          text
        }
        where_left {
          left_title
          left_subtitle
        }
        where_right {
          right_title
          right_topics {
            topic
          }
          right_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 390, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        slider_title
        slides {
          text
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 420, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        founders_left {
          subtitle
          description
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 690, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        founders_right {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 331, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        founders_title
        unsure_title
        unsure_button {
          text
        }
      }
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(query)
  const { seo_title, seo_description } = data.wordpressPage.acf
  const { cta_image, cta_text, cta_button } = data.wordpressPage.acf
  const {
    right_title,
    right_topics,
    right_image,
  } = data.wordpressPage.acf.where_right
  const { left_title, left_subtitle } = data.wordpressPage.acf.where_left
  const { slider_title, slides } = data.wordpressPage.acf
  const {
    founders_left,
    founders_right,
    founders_title,
  } = data.wordpressPage.acf
  const { unsure_title, unsure_button } = data.wordpressPage.acf
  return (
    <Layout>
      <SEO image={ogImage} title={seo_title} description={seo_description} />
      <Hero />
      <Underhero />
      <WhereCanWeHelpYou
        leftTitle={left_title}
        leftSubtitle={left_subtitle}
        rightTopics={right_topics}
        rightTitle={right_title}
        rightImage={right_image}
      />
      <Slider slidesTitle={slider_title} slides={slides} />
      <Unsure unsureButton={unsure_button.text} unsureTitle={unsure_title} />
      <Founders
        foundersTitle={founders_title}
        foundersLeft={founders_left}
        foundersRight={founders_right}
      />
      <CTA image={cta_image} text={cta_text} buttonText={cta_button.text} />
    </Layout>
  )
}

export default IndexPage
