import React from "react"
import Img from "gatsby-image"
import "./style.scss"
const WhereCanWeHelpYou = ({
  leftTitle,
  leftSubtitle,
  rightImage,
  rightTitle,
  rightTopics,
}) => {
  return (
    <section className="section section--helpYou">
      {/* <div className="wrapper"> */}
        <div className="helpYou__left">
          <h2 className="helpYou__title">{leftTitle}</h2>
          <p
            className="helpYou__subtitle"
            dangerouslySetInnerHTML={{ __html: leftSubtitle }}
          ></p>
        </div>
        <div className="helpYou__right">
          {rightImage && (
          <Img
            className="helpYou__image"
            fluid={rightImage.localFile.childImageSharp.fluid}
          />
          )}
          <h3 className="helpYou__title">{rightTitle}</h3>
          <ul className="helpYou__topics">
            {rightTopics.map((topic, i) => (
              <li className="helpYou__topic" key={i}>
                {topic.topic}
              </li>
            ))}
          </ul>
        {/* </div> */}
      </div>
    </section>
  )
}

export default WhereCanWeHelpYou
