import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./style.scss"
import Title from "../../../UI/Title.js"
import Button from "../../../UI/Button/button.js"
const query = graphql`
  {
    wordpressPage(wordpress_id: { eq: 16 }) {
      acf {
        underhero_columns {
          underhero_title
          underhero_subtitle
          underhero_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 446, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
const Underhero = () => {
  const data = useStaticQuery(query)

  return (
    <section id="section-2" className="section section--underhero">
      <div className="wrapper">
        <div className="underhero__wrapper">
          {data.wordpressPage.acf.underhero_columns.map((column, i) => (
            <div className="underhero__column" key={i}>
              {column.underhero_image && (
                <Img
                  className="underhero__image"
                  fluid={column.underhero_image.localFile.childImageSharp.fluid}
                />
              )}
              <div className="column-content__wrapper">
                <Title type="h2" className="underhero__title">
                  {column.underhero_title}
                </Title>
                <p className="underhero__subtitle">
                  {column.underhero_subtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Underhero
