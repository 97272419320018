import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import heroStyles from "./hero.module.scss"
import Title from "../../../UI/Title.js"
import Button from "../../../UI/Button/button"
import heroShapeLeft from "../../../../assets/images/hero_shape_left.svg"
import heroShapeRight from "../../../../assets/images/hero_shape_right.svg"
import heroShapeTop from "../../../../assets/images/hero_shape_top.svg"
import heroArrow from "../../../../assets/images/arrow.svg"
import scrollTo from "gatsby-plugin-smoothscroll"

const query = graphql`
  {
    wordpressPage(wordpress_id: { eq: 16 }) {
      acf {
        hero_title
        hero_subtitle
        hero_button {
          text
        }
      }
    }
  }
`

const Hero = () => {
  const data = useStaticQuery(query)

  return (
    <section className={heroStyles.sectionHero}>
      <img src={heroShapeLeft} className={heroStyles.hero__shapeLeft} />
      <img src={heroShapeRight} className={heroStyles.hero__shapeRight} />
      <img src={heroShapeTop} className={heroStyles.hero__shapeTop} />
      <div className={`${heroStyles.hero__wrapper} wrapper`}>
        <Title className={heroStyles.hero__title} type="h1">
          {data.wordpressPage.acf.hero_title}
        </Title>
        <p className={heroStyles.hero__subtitle}>
          {data.wordpressPage.acf.hero_subtitle}
        </p>
        <Button to="/talk-to-us#free-plan">
          {data.wordpressPage.acf.hero_button.text}
        </Button>
        <Link className={heroStyles.hero__arrow}>
          <img onClick={() => scrollTo("#section-2")} src={heroArrow} />
        </Link>
      </div>
    </section>
  )
}

export default Hero
